var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-gray-800"},[_c('div',{staticClass:"bg-gray-800"},[_c('v-img',{attrs:{"height":_vm.$vuetify.breakpoint.xsOnly ? '30vh' : '50vh',"src":_vm.assetURL('117c02a4-22df-4d88-a0a2-b66093bd7e0b', {
          quality: 80,
          width: 1920,
        })}},[_c('div',{staticClass:"flex justify-center items-center text-white h-full",attrs:{"data-aos":"fade","data-aos-delay":"300","data-aos-anchor-placement":"top-center"}},[_c('div',{staticClass:"w-2/3"},[_c('Title',{attrs:{"title":'our works',"text":'所有專案'}})],1)])])],1),_c('v-container',{staticClass:"py-10"},[_c('v-row',_vm._l((_vm.projects),function(item,i){return _c('v-col',{key:i,staticClass:"cursor-pointer",attrs:{"cols":"6","md":"4","data-aos":"fade","data-aos-offset":"-300"},on:{"click":function($event){return _vm.$router.push({ name: 'project', params: { id: item.id } })}}},[(item.files && item.files.length > 0)?_c('v-img',{attrs:{"src":_vm.assetURL(item.files[0].directus_files_id, {
              quality: 30,
              width: 500,
            }),"height":"200"}}):_vm._e(),_c('div',{staticClass:"text-sm font-bold border-l-3 border-orange-500 px-3 text-white mt-2"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }