var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.project)?_c('div',{staticClass:"bg-gray-800"},[(this.project && this.project.files.length > 0)?_c('v-img',{attrs:{"lazy-src":_vm.assetURL(this.project.files[0].directus_files_id, {
        quality: 10,
        width: 300,
      }),"src":_vm.assetURL(this.project.files[0].directus_files_id, {
        quality: 80,
        width: 1280,
      }),"gradient":"to top, rgba(0,0,0,.5), rgba(0,0,0,.7)","height":_vm.$vuetify.breakpoint.xsOnly ? '30vh' : '50vh'}},[_c('div',{staticClass:"flex justify-center items-center text-white h-full",attrs:{"data-aos":"fade","data-aos-delay":"300"}},[_c('div',{staticClass:"w-2/3"},[_c('div',{staticClass:"text-sm mb-1 text-orange-500"},[_vm._v("PROJECTS")]),_c('div',{staticClass:"text-2xl md:text-3xl font-bold border-l-3 border-orange-500 px-3"},[_vm._v(" "+_vm._s(_vm.project.name)+" ")])])])]):_c('ImgIcon',{staticClass:"h-50vh"},[_c('div',{staticClass:"flex justify-center items-center text-white h-full",attrs:{"data-aos":"fade"}},[_c('div',{staticClass:"md:text-4xl font-bold"},[_vm._v(_vm._s(_vm.project.name))])])]),_c('v-container',[(_vm.project && _vm.project.post)?_c('VueMarkdown',{attrs:{"source":_vm.project.post}}):_vm._e()],1),_c('v-container',{staticClass:"py-10"},[_c('Gallery',{attrs:{"project":_vm.project}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }